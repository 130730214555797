/* 🚀 Intégration de Tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* 🎨 Définition des variables de couleur */
:root {
  --background: #f9fafb; /* Couleur de fond claire */
  --foreground: #1f2937; /* Couleur de texte claire */
  --blue: #2563eb;
  --blue-dark: #1d4ed8;
  --secondary: #e5e7eb;
  --text-light: #ffffff;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #111827; /* Couleur de fond sombre */
    --foreground: #f3f4f6; /* Couleur de texte sombre */
  }
}

/* 🌟 Application globale */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--background);
  color: var(--foreground);
  font-family: var(--font-poppins), sans-serif;
}

/* ✅ Gestion de la hauteur pour Next.js */
#__next {
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* ✅ Main doit gérer le scroll si nécessaire */
main {
  flex: 1;
  overflow: auto;
}

/* ✅ Empêche les débordements horizontaux involontaires */
* {
  max-width: 100vw;
}

/* ✅ Désactiver la scrollbar dans Chrome et Webkit */
::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

/* ✅ Masquer la scrollbar dans Firefox */
html {
  scrollbar-width: none;
}

/* ✅ Amélioration des boutons et cartes via @apply */
@layer components {
  .btn {
    @apply px-6 py-3 bg-blue text-white font-semibold rounded-lg shadow-md hover:bg-blue-dark transition;
  }

  .card {
    @apply p-6 bg-white dark:bg-gray-800 shadow-lg rounded-lg transition hover:shadow-xl;
  }
}
